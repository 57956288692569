<template>
  <div class="home">
    <img alt="Home Logo" src="../assets/Blue.png">
  </div>
  <h1 class="mt-3 display-5 font-bolder fw-bolder m-auto">
    Gym Leaders And Their Happy Partners.
  </h1>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
}
</script>

<style scoped>
@media (min-width: 768px) { 
  h1 {
    width: 75%;
  }
}


</style>